<template>
  <div class="min-h-screen flex">
    <Sidebar />
    <div class="w-2/12"></div>
    <div class="p-4 w-10/12">
      <div class="flex items-center mb-4">
        <div class="is-size-4">{{ $route.name }}</div>
        <b-button
          class="ml-4"
          type="is-success"
          tag="router-link"
          :to="{ name: 'Create Category' }"
          v-if="userHasPermission('write-categories')"
          >New</b-button
        >
      </div>
      <div class="flex items-center justify-between mb-4">
        <div class="flex items-center">
          <b-button
            icon-left="checkbox-marked-outline"
            class=""
            @click="(checkable = !checkable), (checkedRows = [])"
            :disabled="$store.getters.isProcessing"
            v-if="userHasPermission('write-categories')"
            >Select</b-button
          >
          <b-button
            v-if="checkedRows.length"
            icon-left="delete-outline"
            class="ml-4"
            @click="onDelete()"
            :disabled="$store.getters.isProcessing"
            >Delete ({{ checkedRows.length }})</b-button
          >
          <b-button
            v-if="checkedRows.length"
            icon-left="close"
            class="ml-4"
            @click="checkedRows = []"
            :disabled="$store.getters.isProcessing"
            >Clear</b-button
          >
        </div>
        <div @keyup.enter="onSearch">
          <b-input
            v-model="search"
            icon="magnify"
            placeholder="Search"
          ></b-input>
        </div>
      </div>
      <b-table
        :data="data.data"
        :loading="$store.getters.isLoading && !$store.getters.isRouting"
        paginated
        pagination-position="bottom"
        backend-pagination
        :total="data.total"
        :per-page="data.per_page"
        :current-page="data.current_page"
        @page-change="onPageChange"
        backend-sorting
        :default-sort="[
          $store.state.categories.column,
          $store.state.categories.order
        ]"
        @sort="onSort"
        striped
        :checkable="checkable"
        checkbox-position="left"
        :checked-rows.sync="checkedRows"
        detailed
        custom-detail-row
        :opened-detailed="openedRows"
        detail-key="id"
        show-detail-icon
      >
        <!-- columns -->
        <b-table-column field="image" label="Image" width="20" v-slot="props">
          <b-image
            :src="
              props.row.image ? props.row.image.thumb : $default_image_thumb
            "
            :alt="props.row.slug"
            ratio="1by1"
            lazy
          ></b-image>
        </b-table-column>
        <b-table-column field="name" label="Name" v-slot="props" sortable>
          <div>{{ props.row.name }}</div>
          <div>
            <router-link
              v-if="userHasPermission('write-categories')"
              :to="{ name: 'Edit Category', params: { id: props.row.slug } }"
              class="is-size-7 is-link px-2 border"
              >Edit</router-link
            >
          </div>
        </b-table-column>
        <b-table-column field="slug" label="Slug" v-slot="props" sortable>
          {{ props.row.slug }}
        </b-table-column>
        <b-table-column
          field="products_count"
          label="No. of Products"
          v-slot="props"
          sortable
        >
          {{ props.row.products_count }}
        </b-table-column>
        <b-table-column
          field="users_count"
          label="Restricted"
          v-slot="props"
          sortable
        >
          {{ props.row.users_count > 0 ? "Yes" : "No" }}
        </b-table-column>
        <b-table-column
          field="updated_at"
          label="Updated"
          v-slot="props"
          sortable
        >
          {{ props.row.updated_at }}
        </b-table-column>
        <b-table-column
          field="created_at"
          label="Created"
          v-slot="props"
          sortable
        >
          <QuickEditCreatedAt
            :key="props.row.id"
            :id="props.row.slug"
            column="created_at"
            :value="props.row.created_at"
            :orig="props.row.created_at"
            @updated="props.row.created_at = $event"
            save_method="saveCategoriesForm"
          />
        </b-table-column>
        <!-- !columns -->
        <template slot="detail" slot-scope="props">
          <tr v-for="item in props.row.children" :key="item.slug">
            <td v-if="checkable"></td>
            <td>
              <b-checkbox
                v-if="checkable"
                v-model="checkedRows"
                :native-value="item"
              ></b-checkbox>
            </td>
            <td>
              <b-image
                :src="item.image ? item.image.thumb : $default_image_thumb"
                :alt="item.slug"
                ratio="1by1"
                lazy
              ></b-image>
            </td>

            <td>
              <div>{{ item.name }}</div>
              <div>
                <router-link
                  :to="{ name: 'Edit Category', params: { id: item.slug } }"
                  class="is-size-7 is-link px-2 border"
                  >Edit</router-link
                >
              </div>
            </td>
            <td>{{ item.slug }}</td>
            <td>{{ item.products_count }}</td>
            <td>{{ item.users_count > 0 ? "Yes" : "No" }}</td>
            <td>{{ item.updated_at }}</td>
            <td>
              <QuickEditCreatedAt
                :key="item.id"
                :id="item.slug"
                column="created_at"
                :value="item.created_at"
                :orig="item.created_at"
                @updated="item.created_at = $event"
                save_method="saveCategoriesForm"
              />
            </td>
          </tr>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/admin/Sidebar";
import QuickEditCreatedAt from "@/components/admin/QuickEditCreatedAt";
import store from "@/store";
export default {
  components: {
    Sidebar,
    QuickEditCreatedAt
  },
  data: () => ({
    checkable: false,
    checkedRows: [],
    openedRows: []
  }),
  computed: {
    data() {
      return this.$store.state.categories.data;
    },
    search: {
      get() {
        return this.$store.state.categories.search;
      },
      set(newValue) {
        store.commit("setCategoriesSearch", newValue);
      }
    }
  },
  watch: {
    search: _.debounce(function() {
      if (!this.search) this.onSearch();
    }, store.getters.delay),
    data() {
      this.setOpenedRows();
    }
  },
  methods: {
    setOpenedRows() {
      this.openedRows = _.map(this.data.data, c => {
        if (c.children.length) return c.id;
      });
    },
    userHasPermission(data) {
      return store.getters.userHasPermission(data);
    },
    onSearch() {
      store.dispatch(this.$route.meta.dispatch);
    },
    onPageChange(page) {
      store.commit("setCategoriesPage", page);
      store.dispatch(this.$route.meta.dispatch);
    },
    onSort(column, order) {
      store.commit("setCategoriesPage", 1);
      store.commit("setCategoriesColumn", column);
      store.commit("setCategoriesOrder", order);
      store.dispatch(this.$route.meta.dispatch);
    },
    onDelete() {
      store
        .dispatch("deleteSelection", {
          message: `Do you really want to delete these categories?`,
          action: "deleteSelection",
          ids: _.map(this.checkedRows, "id"),
          path: "categories",
          push: "Categories",
          dispatch: this.$route.meta.dispatch
        })
        .then(() => {
          this.checkedRows = [];
          this.checkable = false;
        });
    }
  },
  beforeRouteEnter(to, from, next) {
    if (to.meta.dispatch) {
      store.dispatch(to.meta.dispatch, {}).then(() => {
        next(vm => {
          vm.setOpenedRows();
        });
      });
    }
  }
};
</script>
