<template>
  <div
    @contextmenu.prevent="enabled = true"
    class="is-relative date-input"
    ref="input"
  >
    <template v-if="!userHasPermission('write-products')">
      {{ toYmd(created_at) }}
    </template>
    <template v-else>
      <input
        v-if="!enabled"
        type="text"
        readonly
        class="input appearance-none cursor-pointer"
        :class="[status]"
        v-model="value"
      />
      <b-datepicker
        v-else
        ref="date-picker"
        :disabled="!enabled"
        :date-formatter="toYmd"
        v-model="created_at"
        placeholder="Click to select..."
        position="is-bottom-left"
        open-on-focus
        trap-focus
        :editable="false"
        @input="enabled = false"
      >
      </b-datepicker>
    </template>
  </div>
</template>

<script>
import store from "@/store";
export default {
  props: {
    id: {
      type: [Number, String],
      required: true
    },
    column: {
      type: String,
      required: true
    },
    value: {
      type: [Number, String],
      required: true
    },
    orig: {
      type: [Number, String],
      required: true
    },
    save_method: {
      type: String,
      default: "saveProductsForm"
    }
  },
  data: () => ({
    enabled: false,
    status: "default",
    new_created_at: null
  }),
  methods: {
    userHasPermission(data) {
      return store.getters.userHasPermission(data);
    },
    toYmd(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    saveValue: _.debounce(function() {
      if (
        this.new_created_at == this.orig_created_at ||
        _.isNull(this.new_created_at)
      ) {
        return;
      } else {
        let data = {
          id: this.id,
          column: this.column,
          value: this.new_created_at
        };
        data[this.column] = this.new_created_at;
        store
          .dispatch(this.save_method, data)
          .then(() => {
            this.inputStatus("success");
            this.$emit("updated", this.toYmd(this.new_created_at));
          })
          .catch(() => {
            this.inputStatus("failed");
            this.created_at = this.orig_created_at;
          });
      }
    }, store.state.global.delay),
    inputStatus(type) {
      this.status = type;
      setTimeout(() => {
        this.status = "default";
      }, store.state.global.delay * 3);
    },
    toggleInput() {
      if (!this.$refs["input"].contains(event.target)) this.enabled = false;
    },
    clickDateInput() {
      this.$nextTick(() => {
        this.$refs["date-picker"].focus();
      });
    }
  },
  watch: {
    enabled() {
      if (this.enabled) {
        window.addEventListener("click", this.toggleInput);
        this.clickDateInput();
      } else {
        window.removeEventListener("click", this.toggleInput);
        this.saveValue();
      }
    }
  },
  computed: {
    created_at: {
      get() {
        return new Date(this.value);
      },
      set(newValue) {
        this.new_created_at = newValue;
      }
    },
    orig_created_at() {
      return new Date(this.orig);
    }
  }
};
</script>

<style lang="scss" scoped>
.input {
  width: 120px;
  background: transparent;
  border: 0;
}
.date-input {
  width: 120px;
}

.success {
  background: lighten(hsl(141, 53%, 53%), 40%);
}

.failed {
  background: lighten(hsl(348, 86%, 61%), 40%);
}

.default {
  background: white;
}
</style>
